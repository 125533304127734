import React from 'react'
import Layout from '../components/layout'
import ContactForm from '../components/ContactForm/ContactForm';
import SupportForm from '../components/SupportForm/SupportForm';
import Container from '../components/Container/Container';
import Hero from '../components/Hero/Hero';

const Contact = () => (
  <Layout>
    <Hero interior>
      <h1>
        404 NOT FOUND!
      </h1>
    </Hero>
    <Container>
      <section>
        <p>Looks like that page doesn't exist</p>
      </section>
    </Container>
  </Layout>
)

export default Contact;
